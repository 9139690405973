import React from 'react';

// Components
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import PageSection from '../../components/PageSection';
import FlexRow from '../../components/FlexRow';
import ImgSm from '../../components/ImgSm';
import * as Text from '../../styles/shared/typography';
import * as s from '../../styles/pages/dosing';
import {ThreeColumnLayout, GradientCard, ImageWithScroll, CalloutBanner, PageSeparator, Image } from '../../components';
// Content
import dosingCopy from '../../copy/dosing';
import ImgThreePills from '../../assets/images/img-vhl-three-pills.png';
import Footnotes from '../../components/Footnotes';
import PillsIcon from '../../assets/images/icon-pills-capsules.png';
import CalendarIcon from '../../assets/images/icon-calendar@2x.png';
import UpsetStomachIcon from '../../assets/images/icon-upset-stomach@2x.png';
import DosingGraphic from '../../assets/images/dosing-graphic.svg';
import DosingGraphicMobile from '../../assets/images/dosing-graphic-mobile.svg';
import DosingTable from '../../assets/images/dosing-table.svg';
import styled from 'styled-components';

// SEO INFORMATION
const pageMeta = {
  title: 'Dosing for WELIREG™ (belzutifan) | Von Hippel-Lindau Disease',
  keywords: 'welireg dosing for vhl disease, belzutifan dosing for vhl disease',
  description:  'Health care professionals may find information about dosing, dose reductions, and dose modifications for WELIREG™ (belzutifan) in patients with von Hippel-Lindau (VHL) disease–associated tumors.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/dosing/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/dosing/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","dosageForm":"Convenient, once-daily, oral dosing","administrationRoute":"The recommended dose of WELIREG is 120 mg (three 40-mg tablets) once daily until disease progression or unacceptable toxicity","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf"}`
  ]
}

const anchorLinkItems = ['Dosing', 'Dose Modification'];

const DosingCardData = [
  {
    icon: PillsIcon,
    title: 'Advise patients to swallow tablets whole.',
    description: 'Do not chew, crush, or split WELIREG prior to swallowing.',
  },
  {
    icon: CalendarIcon,
    title: 'If a dose of WELIREG is missed, it can be taken as soon as possible on the same day.',
    description: 'Resume the regular daily dose schedule for WELIREG the next day. Do not take extra tablets to make up for the missed dose.',
  },
  {
    icon: UpsetStomachIcon,
    title: 'If vomiting occurs any time after taking WELIREG, do not retake the dose.',
    description: 'Take the next dose on the next day.',
  },
];

const dosingCalloutBanner = {
  title: 'Explore helpful links and resources',
  continualInvertedButtons: true,
  links: [
    {
      label: 'Mechanism of Action',
      url: '/von-hippel-lindau/mechanism-of-action',
    },
    {
      label: 'Efficacy',
      url: '/von-hippel-lindau/efficacy',
    },
    {
      label: 'Resources & Dispensing',
      url: '/von-hippel-lindau/resources',
    },
  ],
};

const SafetySection = styled(PageSection)`
  padding-top: 16px;
`;

const SafetySectionTitle = styled(Text.H3)`
  font-weight: normal;
  margin-bottom: 1rem;
`;

const DosingPageTitle = styled(Text.H3)`
  font-weight: normal;
  margin-bottom: 30px;
`;

const Spacer30 = styled('div')`
  display: block;
  height: 30px;
`;

const DosingPage = () => {

  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/dosing/" />
      </PageSeo>

      <PageTitle anchorLinksData={anchorLinkItems}>
        Dosing for WELIREG™ (belzutifan)
      </PageTitle>

      <SafetySection sectionWrapperId="Dosing">
          <s.introSection>
          <SafetySectionTitle>{ dosingCopy.introHeader}</SafetySectionTitle>
          <Text.H4>
            { dosingCopy.introSubheader }
          </Text.H4>
          </s.introSection>
      </SafetySection>

      <PageSection center flat>
        <s.dosingImageSection>
          <ImgSm
          imgPath={ImgThreePills}
          imgAlt={ dosingCopy.imgAlt }
          caption={ dosingCopy.imgCaption }
          heading={ dosingCopy.imgSubheading }
        />
        </s.dosingImageSection>
      </PageSection>

      <PageSection>
        <FlexRow gap="20px" mobileGap="24px">
        <ThreeColumnLayout>
          {
            DosingCardData.map((card, index) => (
              <GradientCard key={index} icon={card.icon} title={card.title} description={card.description} linkText={card.linkText} linkUrl={card.linkUrl} />
            ))
          }
        </ThreeColumnLayout>
        </FlexRow>
        <PageSeparator topPadding='40px' bottomPadding='0px' bottomBorder={true}/>
      </PageSection>

      <PageSection flat sectionWrapperId="Dose-Modification">
        <DosingPageTitle>
          Manage adverse reactions with recommended dose modifications
        </DosingPageTitle>
      </PageSection>

      <PageSection flat>
        <ImageWithScroll url={DosingTable} alt={'Adverse Reactions in Patients With von HippelLindau (VHL) Disease–associated Tumors Who Are Taking WELIREG™ (belzutifan), Including Severity, and Corresponding Dose Modifications'} />
        <Spacer30 />
        <Image
          maxWidth={'100%'}
          imgPath={DosingGraphic}
          mobImgPath={DosingGraphicMobile}
          imgAlt='Dose Reduction Instructions for Adverse Reactions in Patients With von Hippel-Lindau (VHL) Disease–associated Tumors Who Are Taking WELIREG™ (belzutifan)'
        />
        <Spacer30 />
      </PageSection>

      <PageSection flat>
        <Footnotes
          items={dosingCopy.footnotes}
        />
      </PageSection>
      <Spacer30 />
        <CalloutBanner {...dosingCalloutBanner} title="Learn more about these attributes of WELIREG:" />
    </>
  );
};

export default DosingPage;
