import ImgCalendar from '../assets/images/illust-calendar.svg';
import ImgStomach from '../assets/images/illust-stomach.svg';
import ImgTablets from '../assets/images/illust-tablets.svg';

const dosingCopy = {
  pageTitle: 'Dosing for WELIREG™ (belzutifan)',
  introHeader: 'Convenient, once-daily, oral dosing',
  introSubheader: 'The recommended dose of WELIREG is 120 mg (three 40-mg tablets) once daily until disease progression or unacceptable toxicity',

  imgAlt: 'WELIREG™ (belzutifan) 40-mg Tablets',
  imgCaption: 'Not actual size.',
  imgSubheading: 'WELIREG should be taken at the same time each day and may be taken with or without food',

  iconCards: [
    {
      title: 'Advise patients to swallow tablets whole.',
      icon: ImgTablets,
      copy: 'Do not chew, crush, or split WELIREG prior to swallowing.',
      alt: '',
    },
    {
      title: 'If a dose of WELIREG is missed, it can be taken as soon as possible on the same day.',
      icon: ImgCalendar,
      copy: 'Resume the regular daily dose schedule for WELIREG the next day. Do not take extra tablets to make up for the missed dose.',
      alt: '',
    },
    {
      title: 'If vomiting occurs any time after taking WELIREG, do not retake the dose.',
      icon: ImgStomach,
      copy: 'Take the next dose on the next day.',
      alt: '',
    },
  ],

  doseModTitle: 'Recommended dose modifications for adverse reactions',

  doseModFlowChart: [
    {
      title: 'Dose reductions',
      copy: '',
    },
    {
      title: 'First dose reduction:',
      copy: '80 mg orally once daily',
    },
    {
      title: 'Second dose reduction:',
      copy: '40 mg orally once daily',
    },
    {
      title: 'Third dose reduction:',
      copy: 'Permanently discontinue',
    },
  ],

  adverseReactionsTable: {
    colHeaders: [
      'Adverse reactions',
      'Severity<sup>a</sup>',
      'Dose modification',
    ],
    rows: [
      [
        {
          rowArHeader: 'Anemia',
          sev: 'Hemoglobin &lt;9 g/dL or transfusion indicated',
          mod: [
            'Withhold until hemoglobin &ge;9 g/dL.',
            'Resume at reduced dose or discontinue depending on the severity of anemia.',
          ],
        },
        {
          sev: 'Life-threatening or urgent intervention indicated',
          mod: [
            'Withhold until hemoglobin &ge;9 g/dL.',
            'Resume at a reduced dose or permanently discontinue.',
          ],
        },
      ],
      [
        {
          rowArHeader: 'Hypoxia',
          sev: 'Decreased oxygen saturation with exercise (eg, pulse oximeter &lt;88%)',
          mod: [
            'Consider withholding until resolved.',
            'Resume at the same dose or at a reduced dose depending on the severity of hypoxia.',
          ],
        },
        {
          sev: 'Decreased oxygen saturation at rest (eg, pulse oximeter &lt;88% or PaO<sub>2</sub> &le;55&nbsp;mm&nbsp;Hg) or urgent intervention indicated',
          mod: [
            'Withhold until resolved.',
            'Resume at reduced dose or discontinue depending on the severity of hypoxia.',
          ],
        },
        {
          sev: 'Life-threatening or recurrent symptomatic hypoxia',
          mod: [
            'Permanently discontinue.',
          ],
        },
      ],
      [
        {
          rowArHeader: 'Other Adverse Reactions',
          sev: 'Grade 3',
          mod: [
            'Withhold dosing until resolved to &le; Grade 2.',
            'Consider resuming at a reduced dose (reduce by 40 mg).',
            'Permanently discontinue upon recurrence of Grade 3.',
          ],
        },
        {
          sev: 'Grade 4',
          mod: [
            'Permanently discontinue.',
          ],
        },
      ],
    ],
  },

  footnotes: [
    {
      ref: 'a',
      copy: 'Graded per NCI CTCAE v5.0.',
      marginBottom: true,
    },
    {
      copy: 'NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events.',
    },
  ],

  bottomBtn: {
    label: 'Explore Resources',
    to: '/von-hippel-lindau/resources',
  },

  buttonBlade: {
    title: 'Learn more about these attributes of WELIREG:',
    buttonLabelLeft: 'Mechanism of Action',
    buttonHrefLeft: '/von-hippel-lindau/mechanism-of-action',
    buttonLabelMid: 'Efficacy',
    buttonHrefMid: '/von-hippel-lindau/efficacy',
    buttonLabelRight: 'Resources',
    buttonHrefRight: '/von-hippel-lindau/resources',
  },

};


export default dosingCopy;
