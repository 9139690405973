import styled from 'styled-components';
import { respondTo } from '../shared/breakpoints';
import * as colors from '../../styles/shared/colors';

export const introSection = styled.div`
  h4 {
    color: ${colors.brandBlueLight};
  }
`;
export const dosingImageSection = styled.div`
  h4 {
    max-width: 580px;
    color: ${colors.brandBlueDark};
  }
  img {
    max-height: 122px;
    width: 100%;
  }
  div {
    max-height: 122px;
    height: auto;
  }

  ${respondTo.sm`

  `}
`;