import styled from 'styled-components';
import { cobalt, primaryButtonBlue } from '../../styles/shared/colors';
import { Body, H4, H5 } from '../../styles/shared/typography';


export const ImgContainer = styled.div`
  display: inline-block;
  width: auto;
  margin-bottom: ${({$hasCaption}) => $hasCaption ? '0' : '16px'};

  img {
    display: block;
    width: auto;
  }
`;

export const ImgCaption = styled(Body)`
  margin: 0 0 24px;
`;

export const ImgHeader = styled(H5)`
  max-width: 550px;
  margin: 0 auto;
  color: ${primaryButtonBlue};
`;