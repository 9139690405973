import React from 'react';
import * as s from './styles';


const ImgSm = ({imgPath, imgAlt, heading, caption}) => {
  return (
    <>
      <s.ImgContainer $hasCaption={!!caption}>
        <img src={imgPath} alt={imgAlt} />
      </s.ImgContainer>

      {caption &&
        <s.ImgCaption>
          { caption }
        </s.ImgCaption>
      }

      <s.ImgHeader>
        { heading }
      </s.ImgHeader>
    </>
  );
};


export default ImgSm;